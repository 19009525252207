import React, { useRef } from "react";
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import axios from "axios";
import  HyphenationCallback  from "./PdfHyphenation";
import { parseDateHyphen } from "../Tools";
Font.registerHyphenationCallback(HyphenationCallback);

const formatCnpj = (text) => {
    if (text) {
        const cnpj = text.replace(/\D/g, '');
        let formattedCnpj = '';

        for (let i = 0; i < cnpj.length; i++) {
            if (i === 2 || i === 5) {
                formattedCnpj += '.';
            } else if (i === 8) {
                formattedCnpj += '/';
            } else if (i === 12) {
                formattedCnpj += '-';
            }
            formattedCnpj += cnpj[i];
        }

        return formattedCnpj;
    }
};

const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        padding: 30,
        display: "flex",
        flexDirection: "column",
    },
    header: {
        marginBottom: 10,
        textAlign: "center",
    },
    image: {
        width: '150px',
        height: '40px',
        marginBottom: '5px'
    },
    textHeader: {
        fontSize: 11,
        fontFamily: 'Helvetica-Bold',
        textAlign: "center",
    },
    section: {
        padding: 10,
    },
    tableContainer: {
        width: '100%',
        alignSelf: 'center',
        border: '1 solid black', // Adicionado borda à tabela
        marginBottom: '10px',
    },
    row: {
        flexDirection: 'row',
        borderBottom: '1 solid black', // Adicionado borda inferior à linha
    },
    cell: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 12,
        padding: 1,
        width: '30%',
        paddingLeft: '5px',
    },
    cellHeader: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 11,
        padding: 3,
        width: '100%',
    },
    viewRow: {
        flexDirection: 'row',
        fontSize: 12,
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    view2: {
        fontSize: 12,
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: '20px',
    },
    text1: {
        margin: '10px',
        fontSize: 12,
    },
    text3: {
        fontSize: 12,
    },
    text4: {
        fontSize: 12,
        paddingTop: '6px',
        marginTop: '20px',
        borderTop: '1px solid black',
    },
    text2: {
        fontSize: 12,
        paddingTop: '6px',
        borderTop: '1px solid black',
    },
    cell1: {
        width: '100%',
        fontSize: 11,
        padding: 3,
        borderLeftWidth: '1px',
        borderLeftColor: 'black',
    },
    headerCell: {
        fontWeight: 'bold',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textLine: {
        textDecoration: 'underline',
        fontSize: 12,
    },

    justify: {
        textAlign: 'justify',
    },
});

const formatDate = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = months[Number(dateParts[1]) - 1];
    const day = Number(dateParts[2]);

    return `${day} de ${month} de ${year}`;
};
const SingularePdf1 = ({ dados }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const calcData = JSON.parse(localStorage.getItem('calcData'));
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    const currentZoom = window.devicePixelRatio;
    const endereco = `${emissor.rua}, ${emissor.numero} - ${emissor.bairro} - ${emissor.cidade}/${emissor.estado} CEP: ${emissor.cep}`;
    const dadosBancarios = `Banco: ${emissor.banco}/Agência: ${emissor.agency}/Conta:${emissor.conta}`;
    console.log(currentZoom);
    const pdfRef = useRef();
    const linhaDoVencimento = calcData[calcData.length - 1]
    const linhaPrimeira = calcData[0]
    const dataPrimeira = allInfoArray[7];
    const now = formValues.dataEmissao ? parseDateHyphen(formValues.dataEmissao):new Date();
    const dataHoje = now.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    const lugarEData = `${investidor.cidade}, ${dataHoje}`;
    console.log(dataDoVencimento);

    const avalistas = emissor.avalistas || [];


    // Calcula o tamanho do componente PDFViewer com base no zoom
    return ( /*Componente Repetido */
        <PDFViewer width={'90%'} height={'85%'}>
            <Document>
                <Page style={styles.page} size='A4'>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                        <Text style={[styles.textHeader, styles.textBold]}>CONDIÇÕES NEGOCIAIS DO INSTRUMENTO PARTICULAR DE PRESTAÇÃO DE</Text>
                        <Text style={styles.textHeader}>SERVIÇOS DE ESCRITURAÇÃO E AGENTE DE LIQUIDAÇÃO</Text>
                    </View>

                    <View style={styles.section}>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Dados de Identificação</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { borderBottomWidth: '0px', borderBottomColor: 'black' }]}>Número do Termo</Text> <Text style={[styles.cell1]}>1</Text>
                            </View>
                        </View>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { width: '100%' }]}>CONTRATANTE / EMISSORA</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Nome:</Text><Text style={[styles.cell1]}>{emissor.razaoSocial}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>CNPJ:</Text><Text style={[styles.cell1]}>{formatCnpj(emissor.cnpj)}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Endereço:</Text><Text style={[styles.cell1]}>{endereco}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Email:</Text><Text style={[styles.cell1]}>{emissor.email}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Dados Bancários:</Text><Text style={[styles.cell1]}>{dadosBancarios}</Text>
                            </View>
                        </View>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cellHeader]}>RESPONSÁVEL FINANCEIRO DA CONTRATANTE / EMISSORA</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Nome:</Text><Text style={[styles.cell1]}>Rosana Winterink</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>CPF/CNPJ:</Text><Text style={[styles.cell1]}>222.201.218-01</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Endereço:</Text><Text style={[styles.cell1]}>Rua Silvestre Vasconcelos Calmon 51, sala 1612</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Email:</Text><Text style={[styles.cell1]}>rosana@c2cards.digital</Text>
                            </View>
                        </View>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>DADOS DO ATIVO</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Natureza do Ativo:</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>Nota Comercial</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Série:</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>Única</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Emissão:</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>{dados.emissao}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Data de Emissão:</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>{dataHoje}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Data de Vencimento:</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>{dataDoVencimento}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Valor da Operação:</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>{allInfoArray[6]}</Text>
                            </View>
                        </View>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { width: '100%' }]}>SERVIÇOS CONTRATADOS</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>X</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>1. Serviço de Escrituração</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>X</Text><Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>2. Agente de Liquidação</Text>
                            </View>
                            <View style={styles.tableContainer}>
                                <View style={styles.row}>
                                    <Text style={[styles.cell]}>REMUNERAÇÃO</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={[styles.cell]}>Remuneração {'\n'} Escrituração</Text><Text style={[styles.cell1]}>0,15% do Valor de Aquisição do Ativo</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={[styles.cell]}>Periodicidade</Text><Text style={[styles.cell1]}>Parcela única</Text>
                                </View><View style={styles.row}>
                                    <Text style={[styles.cell]}>Taxa de Implantação de Liquidante</Text><Text style={[styles.cell1]}>N/A</Text>
                                </View><View style={styles.row}>
                                    <Text style={[styles.cell]}>Periodicidade</Text><Text style={[styles.cell1]}>N/A</Text>
                                </View><View style={styles.row}>
                                    <Text style={[styles.cell]}>Índice de correção</Text><Text style={[styles.cell1]}>IPCA</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                        <Text style={[styles.textHeader, styles.textBold]}>CONDIÇÕES NEGOCIAIS DO INSTRUMENTO PARTICULAR DE PRESTAÇÃO DE</Text>
                        <Text style={styles.textHeader}>SERVIÇOS DE ESCRITURAÇÃO E AGENTE DE LIQUIDAÇÃO</Text>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>Data de pagamento</Text><Text style={[styles.cell1]}>5º dia útil após a data de emissão</Text>
                            </View>
                            <View style={[styles.row]}>
                                <Text style={[styles.cell]}>Alíquota de{"\n"}
                                    Imposto(no caso{"\n"}
                                    de escrituração)</Text><Text style={[styles.cell1]}>Os valores referidos acima {"\n"}serão acrescidos dos valores dos tributos que incidem{"\n"} sobre a prestação desses serviços (gross up), tais como:{"\n"} (i) Imposto Sobre Serviços de Qualquer Natureza{"\n"}( - ISSQN; (ii) Contribuição para o Programa de{"\n"} Integração Social - PIS; iii)Contribuição para{"\n"} o Financiamento da Seguridade Social</Text>
                            </View>
                        </View>


                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={[styles.cell]}>                                 </Text>
                                <Text style={[styles.cell1, { fontFamily: 'Helvetica-Bold' }]}>- COFINS; Imposto sobre a Renda e Proventos de{"\n"}
                                    Qualquer Natureza – IR e (iv) outros tributos que venham{"\n"}
                                    a incidir sobre a remuneração da Singulare, nas{"\n"}
                                    respectivas alíquotas vigentes a cada data de{"\n"}
                                    pagamento, sendo certo que serão acrescidos aos{"\n"}
                                    pagamentos valores adicionais, de modo que a Singulare{"\n"}
                                    receba os mesmos valores que seriam recebidos caso{"\n"}
                                    nenhum dos impostos elencados neste item.</Text>
                            </View>
                        </View>
                        <View>
                            <Text style={[styles.textLine, { fontFamily: 'Helvetica-Bold', paddingHorizontal: 8, marginBottom: 20 }, styles.justify]}>
                                Ao assinar as Condições Negociais, Contratante e Responsável Financeiro
                                declaram que receberam cópia, leram e compreenderam o Contrato de Prestação
                                de Serviços de Escrituração e Agente de Liquidação registrado eletronicamente
                                no 5° Oficial de Registro de Títulos e Documentos da Comarca de São Paulo –
                                SP sob nº 1.629.613, em 16 de janeiro de 2023 e estão cientes de que, juntamente
                                com a Singulare Corretora de Títulos e Valores Mobiliários S.A. se vincularão a
                                ele integralmente em todos os seus termos e condições
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.text1}>
                                {lugarEData}
                            </Text>
                            <Text style={[styles.text1, { fontFamily: 'Helvetica-Bold' }]}>
                                SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.
                            </Text>
                        </View>
                        <View style={styles.viewRow}>
                            <Text style={[styles.text2, { marginTop: 20 }]}>
                                Nome: Daniel Doll Lemos
                            </Text>
                            <Text style={[styles.text2, { marginTop: 20 }]}>
                                Nome: Leandro Mendes Davanso
                            </Text>
                        </View>
                        <View style={styles.viewRow}>
                            <View style={styles.view2}>
                                <Text style={[styles.text3, { fontFamily: 'Helvetica-Bold', marginTop: 20 }]}>
                                    CONTRATANTE/EMISSORA
                                </Text>
                                <Text style={[styles.text4, { width: '90%' }]}>
                                    Nome: {emissor.representante}
                                </Text>
                                <Text style={styles.text3}>Cargo: {emissor.cargo}</Text>
                            </View>
                            <View style={styles.view2}>
                                <Text style={[styles.text3, { fontFamily: 'Helvetica-Bold', marginTop: 20 }]}>
                                    RESPONSÁVEL FINANCEIRO
                                </Text>
                                <Text style={[styles.text4, { width: '90%' }]}>
                                    Nome: Rosana Winterink
                                </Text>
                                <Text style={styles.text3}>Cargo: Responsável Financeira</Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}
export default SingularePdf1;